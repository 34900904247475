<!--
 * @Author: zhouxingtong
 * @Email: 495144492@qq.com
 * @Date: 2023-04-17 18:15:27
 * @LastEditors: zhouxingtong
 * @LastEditTime: 2024-12-10 15:14:47
 * @Description: 
-->
<template>
	<div class="content-wrap" ref="container">
		<a-row type="flex" justify="start">
			<a-col :span="3">
				<div class="side-menu">
					<a-menu :selectedKeys="activeMenu" mode="inline">
						<a-menu-item :key="item.name" v-for="item in menuList">
							<router-link :to="{name: item.name}">{{ item.title }}</router-link>
						</a-menu-item>
					</a-menu>
				</div>
			</a-col>
			<a-col :span="21">
				<div class="main" id="main">
					<!-- <breadcrumb /> -->
					<router-view />
				</div>
			</a-col>
		</a-row>
	</div>
</template>
<script>
import Breadcrumb from '@/layouts/Breadcrumb'
export default {
	name: 'MenuManage',
	components: {Breadcrumb},
	data() {
		return {
			menuList: [
				{title: '菜单设置', name: 'MenuSetting'},
				{title: '规格设置', name: 'ConfigSetting'},
				{title: '电子菜单', name: 'ElectronMenu'}
			]
		}
	},
	computed: {
		activeMenu() {
			return [this.$route.name]
		}
	}
}
</script>
<style scoped lang="less">
.ant-menu-inline {
	border-right: 0;
}

.content-wrap {
	height: 100%;
	min-height: calc(100vh - 66px);
	background-color: #f0f2f5;
}

.side-menu {
	padding-top: 25px;
	height: calc(100vh - 66px);
	background-color: #fff;
	box-sizing: border-box;
}

.main {
	padding: 20px;
	height: 100%;
	min-height: calc(100vh - 66px);
	overflow: hidden;
}
</style>
